/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mergeArraysByCommonKeys, mergeItemIntoArray } from 'utils/merge-arrays';
import {
    Asset,
    ProductsV2Rewards,
    ProductV2,
    ProductV2RatesGains,
    ProductV2ReservedAmount,
    ProductV2VaultGains,
    TimeInterval,
    ProductsV2PendingOrder,
} from 'entities/productsV2';
import _ from 'lodash';
import { flattenAggregatedProducts, mapAssetToProductV2 } from 'utils/products';
import { Graph, TimeFrameKey } from 'entities';
import { ProductCopy } from 'entities/products';
import { toTitleCase } from 'utils/casing';
import { orderBy } from 'utils/sorting';

export interface ProductsV2 {
    list: ProductV2[];
    assets: Asset[];
    // Portfolio
    portfolioValue: number;
    portfolioCashBalance: number;
    portfolioCashReserved: number;
    portfolioGainsAmount: number;
    portfolioGainsPercentage: number;
    portfolioCanDeposit: boolean;
    portfolioCanWithdraw: boolean;
    rewards: ProductsV2Rewards | null;
    graphs: {
        [key in '1' | '7' | '30' | '90' | '180' | '365' | 'all']?: {
            timeframe: string;
            currency: string;
            data: any[];
        };
    };
    // List
    isLoadingList: boolean;
    errorList: string | null;
    // Rewards
    isLoadingRewards: boolean;
    errorRewards: string | null;
    // Item
    isLoadingItem: boolean;
    errorListItem: string | null;
    // Gains
    isLoadingRatesGains: boolean;
    errorRatesGains: string | null;
    // Vault gains
    isLoadingVaultGains: boolean;
    errorVaultGains: string | null;
    // Reserved amounts
    isLoadingReservedAmounts: boolean;
    errorReservedAmounts: string | null;
    // Assets
    isLoadingAssets: boolean;
    errorAssets: string | null;
    // Display rates
    isLoadingDisplayRates: boolean;
    errorDisplayRates: string | null;
    // Pending orders
    isLoadingPendingOrders: boolean;
    errorPendingOrders: string | null;
    // TimeIntervals
    timeIntervalPortfolio: TimeInterval;
    timeIntervalProducts: TimeInterval;
    isLoadingProductContent: boolean;
    errorProductContent: string | null;
}

const initialGraphDataForTimeframe = {
    data: [] as Graph[],
    timeframe: '',
    currency: '',
};

const initialProductsV2State: ProductsV2 = {
    list: [],
    assets: [],
    portfolioValue: 0,
    portfolioCashBalance: 0,
    portfolioCashReserved: 0,
    portfolioGainsAmount: 0,
    portfolioGainsPercentage: 0,
    portfolioCanDeposit: false,
    portfolioCanWithdraw: false,
    graphs: {
        7: initialGraphDataForTimeframe,
        30: initialGraphDataForTimeframe,
        90: initialGraphDataForTimeframe,
        180: initialGraphDataForTimeframe,
        365: initialGraphDataForTimeframe,
        all: initialGraphDataForTimeframe,
    },
    rewards: {
        currentRewardTierName: '',
        lifetimeRewardsPoints: 0,
        pointsForNextTier: 0,
        referrerRewardsPointsAsFunctional: 0,
        referrerRewardsPoints: 0,
        referralDepositAmountAsFunctional: 0,
        referralCount: 0,
        rewardTierDiscountPercentage: 0,
        rewardsTierBenefits: [],
    },
    // List
    isLoadingList: true,
    errorList: null,
    // Rewards
    isLoadingRewards: true,
    errorRewards: null,
    // Item
    isLoadingItem: true,
    errorListItem: null,
    // Gains
    isLoadingRatesGains: true,
    errorRatesGains: null,
    // Vault gains
    isLoadingVaultGains: true,
    errorVaultGains: null,
    // Reserved amounts
    isLoadingReservedAmounts: true,
    errorReservedAmounts: null,
    // Assets
    isLoadingAssets: true,
    errorAssets: null,
    // Display rates
    isLoadingDisplayRates: true,
    errorDisplayRates: null,
    // Pending orders
    isLoadingPendingOrders: true,
    errorPendingOrders: null,
    // TimeIntervals
    timeIntervalPortfolio: { id: '0', value: '1', name: '', label: '1' },
    timeIntervalProducts: { id: '0', value: '1', name: '', label: '1' },
    // Product content
    isLoadingProductContent: false,
    errorProductContent: null,
};

const productsV2 = createSlice({
    name: 'productsV2',
    initialState: initialProductsV2State,
    reducers: {
        // Aggregated list
        setProductsAggregatedList(state: ProductsV2, action: PayloadAction<any>) {
            const assets = state?.assets?.slice()?.map((a: Asset) => ({
                ...a,
                color: a?.colors?.primary,
                code: a?.code,
              }));
        
              // BL: flatten response to match ProductV2
              const flattenedData = flattenAggregatedProducts(action.payload);
        
              // BL: If the assets are already populated, merge the assets data into productsV2.list
              if (state.assets?.length > 0) {
                var result = flattenedData.list
                  .map((p: ProductV2) => {
                    const asset =
                      assets?.find?.((a: Asset) => a.code === p.code) || ({} as Asset);
        
                    return {
                      ...p,

                      category: asset?.category,
                      assetTags: asset?.assetTags,
                      disclaimer: asset?.disclaimer,
                      description: asset?.description,
                      network: asset?.network,
                      color: asset?.colors?.primary || asset?.colors?.secondary,
                      longName: asset?.longName,
                      pluralName: asset?.pluralName,
                      displayOrder: asset?.displayOrder,
                      typeCode: asset?.typeCode,
                      active: asset?.active,
                      factsheet: asset?.factsheet,
                      hasFacts: asset?.hasFacts,
                      id: asset?.id,
                      keywords: asset?.keywords,
                      methodology: asset?.methodology,
                      navigation: asset?.navigation,
                      whitepaper: asset?.whitepaper,
                      // BUNDLES
                      constituents: asset?.constituents,
                      targetWeight: asset?.targetWeight,
                      endpointId: asset?.endpointId,
                      holdings: asset?.holdings,
                      referralCode: asset?.referralCode,
                      totalExpenseRatio: asset?.totalExpenseRatio,
                      // BUNDLES & THEMES
                      rebalancingFrequency: asset?.rebalancingFrequency,
                      // CONTENT
                      content:
                          state.list.find((a: ProductV2) => a.code === asset.code)?.content ??
                          asset.productCopy,
                    };
                  })
                  ?.sort(orderBy('displayOrder', 'asc'));
                state.list = result;
              } else {
                const orderedFlattenedData = flattenedData.list?.sort(orderBy('displayOrder', 'asc'))
                state.list = orderedFlattenedData;
              }
        
              state.portfolioValue = flattenedData.portfolioValue;
              state.portfolioCashBalance = flattenedData.cashBalance;
              state.portfolioGainsAmount = flattenedData.gainsAmount;
              state.portfolioGainsPercentage = flattenedData.gainsPercentage;
              state.portfolioCashReserved = flattenedData.cashReserved;
              state.portfolioCanDeposit = flattenedData.canDeposit;
              state.portfolioCanWithdraw = flattenedData.canWithdraw;
        },
        setProductsAggregatedListLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingList = action.payload;
        },
        setProductsAggregatedListError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorList = action.payload;
        },
        // Rewards
        setProductsRewards(state: ProductsV2, action: PayloadAction<any>) {
            state.rewards = action.payload;
        },
        setProductsRewardsLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingRewards = action.payload;
        },
        setProductsRewardsError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorRewards = action.payload;
        },
        // Merge items into list[]
        setProductsList(state: ProductsV2, action: PayloadAction<any>) {
            if (state.list) {
                if (state.assets?.length > 0) {
                    const mergedArray = mergeArraysByCommonKeys(
                        action.payload,
                        state.assets.map((a: Asset) => ({
                            code: a?.code,
                            color: a?.colors?.secondary || a?.colors?.primary,
                            longName: a?.longName,
                            pluralName: a?.pluralName,
                            displayOrder: a.typeCode === 'FIAT' ? -1 : a?.displayOrder,
                            category: a?.category,
                            network: a?.network,
                            disclaimer: a?.disclaimer,
                            whitepaper: a?.whitepaper,
                            factsheet: a?.factsheet,
                            methodology: a?.methodology,
                            constituents: a?.constituents,
                            endpointId: a?.endpointId,
                            content: a?.productCopy,
                        })),
                        'code'
                    );

                    state.list = mergedArray;
                } else {
                    state.list = action.payload;
                }
            }
        },
        setProductsListLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingList = action.payload;
        },
        setProductsListError(state: ProductsV2, action: PayloadAction<string | null>) {
            if (state.errorList) {
                state.errorList = action.payload;
            }
        },
        // Merge individual items into list
        setProductsItem(state: ProductsV2, action: PayloadAction<ProductV2>) {
            const mergedArray = mergeItemIntoArray(
                state.list.slice(),
                action.payload as ProductV2,
                'code',
                'productCode'
            );

            state.list = mergedArray;
        },
        setProductsItemLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingItem = action.payload;
        },
        setProductsItemError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorListItem = action.payload;
        },
        // Rates gains
        setProductsRatesGains(state: ProductsV2, action: PayloadAction<ProductV2RatesGains[]>) {
            const mergedArray: ProductV2[] = mergeArraysByCommonKeys(
                state.list.slice(),
                action.payload as ProductV2RatesGains[],
                'code',
                'productCode'
            ).map((product: ProductV2) => {
                const displayOrder = state.assets.find((a: Asset) => a.code === product.code)
                    ?.displayOrder;

                const result =
                    product?.amount && product?.rate
                        ? { ...product, fiatValue: product?.amount * product?.rate, displayOrder }
                        : { ...product, displayOrder }; // BA: Calculate amount product.amount * product.rate
                return result;
            });

            const portfolioValue = _.sum(
                mergedArray
                    .filter((p: ProductV2) => p?.fiatValue)
                    .map((p: ProductV2) => p?.fiatValue)
            );

            const portfolioCashBalance = mergedArray.find((p: ProductV2) => p.isFunctional)
                ?.amount as number;

            const portfolioGains = _.sum(
                mergedArray
                    .filter((p: ProductV2) => p?.gainAmount)
                    .map((p: ProductV2) => p?.gainAmount)
            );

            const portfolioListWithPieData = mergedArray.map((p: ProductV2) => {
                return p?.amount && p?.fiatValue && portfolioValue > 0
                    ? {
                          ...p,
                          breakdownPercentage: (p?.fiatValue / portfolioValue) * 100,
                      }
                    : p;
            });
            state.portfolioValue = portfolioValue;
            state.portfolioCashBalance = portfolioCashBalance;
            state.portfolioGainsAmount = portfolioGains;
            // eslint-disable-next-line no-bitwise
            state.portfolioGainsPercentage = portfolioGains / (portfolioValue || 1);
            state.list = portfolioListWithPieData;
        },
        setProductsRatesGainsLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingRatesGains = action.payload;
        },
        setProductsRatesGainsError(state: ProductsV2, action: PayloadAction<string | null>) {
            if (state.errorRatesGains) {
                state.errorRatesGains = action.payload;
            }
        },
        // Vault gains
        setProductsVaultGains(state: ProductsV2, action: PayloadAction<ProductV2VaultGains[]>) {
            const mergedArray = mergeArraysByCommonKeys(
                state.list.slice(),
                action.payload as ProductV2VaultGains[],
                'code',
                'productCode'
            ).map((p: ProductV2) => ({
                ...p,
                displayOrder: state.assets?.find((a: Asset) => a.code === p.code)?.displayOrder,
            }));

            state.list = mergedArray;
        },
        setProductsVaultGainsLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingVaultGains = action.payload;
        },
        setProductsVaultGainsError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorRatesGains = action.payload;
        },
        // Reserved amounts
        setProductsReservedAmounts(
            state: ProductsV2,
            action: PayloadAction<ProductV2ReservedAmount[]>
        ) {
            const mergedArray = mergeArraysByCommonKeys(
                state.list.slice(),
                action.payload,
                'code'
            ).map((p: ProductV2) => ({
                ...p,
                displayOrder: state.assets?.find((a: Asset) => a.code === p.code)?.displayOrder,
            }));

            state.list = mergedArray;
        },
        setProductsReservedAmountsLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingReservedAmounts = action.payload;
        },
        setProductsReservedAmountsError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorReservedAmounts = action.payload;
        },
        // Time Intervals
        setPortfolioTimeInterval(state: ProductsV2, action: PayloadAction<TimeInterval>) {
            state.timeIntervalPortfolio = action.payload;
        },
        setProductsTimeInterval(state: ProductsV2, action: PayloadAction<TimeInterval>) {
            state.timeIntervalProducts = action.payload;
        },
        // Content
        setProductContent(state: ProductsV2, action: PayloadAction<any>) {
            if (state.list.length > 0) {
                const mergedArray = mergeItemIntoArray(
                    state.list.slice(),
                    {
                        code: action.payload.code,
                        content: action.payload.productCopy,
                    },
                    'code'
                );

                state.list = mergedArray;
            } else {
                const items: ProductV2[] = [
                    {
                        code: action.payload.code,
                        content: action.payload.productCopy,
                        category: action.payload.category,
                        colors: action.payload.colors,
                        description: action.payload.description,
                        color: action.payload.colors?.secondary || action.payload.colors?.primary,
                        longName: action.payload.longName,
                        pluralName: action.payload.pluralName,
                        displayOrder:
                            action.payload.typeCode === 'FIAT' ? -1 : action.payload.displayOrder,
                        network: action.payload.network,
                        disclaimer: action.payload.disclaimer,
                        whitepaper: action.payload.whitepaper,
                        factsheet: action.payload.factsheet,
                        methodology: action.payload.methodology,
                        constituents: action.payload.constituents,
                        endpointId: action.payload.endpointId,
                        productName: action.payload.name,
                        name: action.payload.name,
                        productType: toTitleCase(action.payload.typeCode) as string,
                        typeCode: action.payload.typeCode,
                        productTypeName: toTitleCase(action.payload.typeCode) as string,
                    },
                ];

                state.list = items;
            }
        },
        setProductContentLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingProductContent = action.payload;
        },
        setProductContentError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorProductContent = action.payload;
        },
        // References assets
        setReferencesAssets(state: ProductsV2, action: PayloadAction<Asset[]>) {
            if (state.assets.length > 0) {
                const mergedAssetArray = mergeArraysByCommonKeys(
                    state.assets.slice(),
                    action.payload as Asset[],
                    'code'
                );
                state.assets = mergedAssetArray;
            } else {
                state.assets = action.payload;
            }

            if (state.list?.length > 0) {
                const mergedArray = mergeArraysByCommonKeys(
                    state.list.slice(),
                    action.payload.map((asset: Asset) => ({
                        code: asset?.code,
                        category: asset?.category,
                        assetTags: asset?.assetTags,
                        disclaimer: asset?.disclaimer,
                        description: asset?.description,
                        network: asset?.network,
                        color: asset?.colors?.primary || asset?.colors?.secondary,
                        longName: asset?.longName,
                        pluralName: asset?.pluralName,
                        displayOrder: asset?.displayOrder,
                        typeCode: asset?.typeCode,
                        active: asset?.active,
                        colors: asset?.colors,
                        factsheet: asset?.factsheet,
                        hasFacts: asset?.hasFacts,
                        id: asset?.code,
                        keywords: asset?.keywords,
                        methodology: asset?.methodology,
                        navigation: asset?.navigation,
                        whitepaper: asset?.whitepaper,
                        // BUNDLES
                        constituents: asset?.constituents,
                        targetWeight: asset?.targetWeight,
                        // THEMES
                        distributions: asset?.distributions,
                        endpointId: asset?.endpointId,
                        holdings: asset?.holdings,
                        referralCode: asset?.referralCode,
                        totalExpenseRatio: asset?.totalExpenseRatio,
                        // BUNDLES & THEMES
                        rebalancingFrequency: asset?.rebalancingFrequency,
                        // CONTENT
                        content:
                            state.list.find((a: ProductV2) => a.code === asset.code)?.content ??
                            asset.productCopy,
                    })),
                    'code'
                );

                state.list = mergedArray;
            }
        },
        setReferencesAssetsLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingAssets = action.payload;
        },
        setReferencesAssetsError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorAssets = action.payload;
        },
        // Display rates
        setProductDisplayRates(state: ProductsV2, action: PayloadAction<any>) {
            const ratesArray = Object.keys(action.payload).map((key: string) => ({
                code: key.split('_')?.[0],
                rate: action.payload[key],
            }));

            const mergedArray = mergeArraysByCommonKeys(state.list.slice(), ratesArray, 'code').map(
                (p: ProductV2) => ({
                    ...p,
                    displayOrder: state.assets?.find((a: Asset) => a.code === p.code)?.displayOrder,
                })
            );

            state.list = mergedArray;
        },
        setProductDisplayRatesLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            // if (state.isLoadingDisplayRates) {
            state.isLoadingDisplayRates = action.payload;
            // }
        },
        setProductDisplayRatesError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorDisplayRates = action.payload;
        },
        // Pending orders
        setProductsListPendingOrders(
            state: ProductsV2,
            action: PayloadAction<ProductsV2PendingOrder[]>
        ) {
            const pendingItems = action.payload.map((product: ProductsV2PendingOrder) => ({
                code: product.code,
                pendingAmounts: product,
            }));
            if (state.list?.length > 0) {
                const mergedArray = mergeArraysByCommonKeys(
                    state.list.slice(),
                    pendingItems,
                    'code'
                ).map((p: ProductV2) => ({
                    ...p,
                    displayOrder: state.assets?.find((a: Asset) => a.code === p.code)?.displayOrder,
                }));
                state.list = mergedArray;
            }
        },
        setProductsListPendingOrdersLoading(state: ProductsV2, action: PayloadAction<boolean>) {
            state.isLoadingPendingOrders = action.payload;
        },
        setProductsListPendingOrdersError(state: ProductsV2, action: PayloadAction<string | null>) {
            state.errorPendingOrders = action.payload;
        },
        // Graphs
        setAllGraphsData(
            state,
            action: PayloadAction<{
                timeFrame: TimeFrameKey;
                currency: string;
                data: Graph[];
            }>
        ) {
            state.graphs = {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                ...state?.graphs,
                [action.payload.timeFrame as string]: action.payload,
            };
        },
    },
});

export const {
    setProductsAggregatedList,
    setProductsAggregatedListLoading,
    setProductsAggregatedListError,
    setProductsRewards,
    setProductsRewardsLoading,
    setProductsRewardsError,
    setProductsList,
    setProductsListLoading,
    setProductsListError,
    setProductsItem,
    setProductsItemLoading,
    setProductsItemError,
    setProductsRatesGains,
    setProductsRatesGainsLoading,
    setProductsRatesGainsError,
    setProductsVaultGains,
    setProductsVaultGainsLoading,
    setProductsVaultGainsError,
    setProductsReservedAmounts,
    setProductsReservedAmountsLoading,
    setProductsReservedAmountsError,
    setPortfolioTimeInterval,
    setProductsTimeInterval,
    setProductContent,
    setProductContentLoading,
    setProductContentError,
    setReferencesAssets,
    setReferencesAssetsLoading,
    setReferencesAssetsError,
    setProductDisplayRates,
    setProductDisplayRatesLoading,
    setProductDisplayRatesError,
    setProductsListPendingOrders,
    setProductsListPendingOrdersLoading,
    setProductsListPendingOrdersError,
    setAllGraphsData,
} = productsV2.actions;

export default productsV2.reducer;
